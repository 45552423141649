import { consola } from 'consola';
import {
    ProductCategoryTypeDto,
    ProductCategoryTypeEntity,
    ProductCollectionTypeEntity,
    ProductTypeEntity,
    ProductTypeDto,
    ProductBrandTypeEntity,
    ProductBrandTypeDto
} from './useProductType';
import { useParseData } from '../../../utils/parse.utils';
import { useGenerateUrl } from '../../../utils/url.utils';

export const useProductUtils = (settings: any) => {
    const { generateDefaultFieldDirectus, getImageField, } = useParseData()
    const { generateUrlByNameRoute } = useGenerateUrl(settings)


    const generateProductDto = (item: ProductTypeEntity): ProductTypeDto | any => {
        try {
            let output: any = {
                ...item,
                images: item.images ? item.images.map((ite: any) => getImageField(ite, 'directus_files_id')) : null,
                price: item.price ? parseFloat(item.price).toLocaleString("vi") : '',
                url: generateUrlByNameRoute('product_detail', {
                    id: item.id,
                    slug: item.slug
                }),
                translations: {
                    title: item.title,
                    decsription: item.description
                },
            }

            if (item.features && Array.isArray(item.features) && item.features[0]) {
                output['features'] = item.features.reduce((previousValue: any, currentValue: any) => {
                    if (currentValue && typeof currentValue === 'object') {
                        previousValue.push({
                            id: currentValue.attributes_id.id,
                            title: currentValue.attributes_id.title
                        })
                    }
                    return previousValue
                }, [])
            }

            if (item.collection) {
                output['collection'] = {
                    id: item.collection.id,
                    title: item.collection.title,
                    description: item.collection.description,
                    slug: item.collection.slug,
                    thumbnail: item.collection.thumbnail,
                    image: item.collection.images ? item.collection.images.map((ite: any) => getImageField(ite, "directus_files_id"))[0] : null,
                    url: generateUrlByNameRoute('collection_detail', {
                        id: item.collection.id,
                        slug: item.collection.slug
                    })
                }
            }
            if (item.children && Array.isArray(item.children) && item.children[0]) {

                let attributes: Array<any> = item.children
                    // .sort(function (a: any, b: any) {
                    //     if (a.price < b.price) {
                    //         return -1
                    //     }
                    //     if (a.price > b.price) {
                    //         return 1
                    //     }
                    //     return 0
                    // })
                    .map((ite: any) => {
                        if (ite.color && typeof ite.color === 'object' || ite.color && typeof ite.color === 'object' && ite.size && typeof ite.size === 'object') {
                            return {
                                color: {
                                    // ...ite,
                                    sku: ite.sku,
                                    price: ite.price ? parseFloat(ite.price).toLocaleString("vi") : '',
                                    quick_detail: ite.quick_detail,
                                    ...ite.color,
                                    images: ite.images ? ite.images.map((ie: any) => getImageField(ie, "directus_files_id")) : null,
                                    variants: item.children?.reduce((previousValue: any, currentValue: any) => {
                                        if (currentValue.color.id === ite.color.id) {
                                            previousValue.push({
                                                id: ite.color.id,
                                                icon: ite.color.icon,
                                                title: ite.color.title,
                                                size: currentValue.size || null,
                                                product: {
                                                    // ...currentValue,
                                                    id: currentValue.id,
                                                    images: currentValue.images ? currentValue.images.map((ite: any) => getImageField(ite, "directus_files_id")) : null,
                                                    quick_detail: currentValue.quick_detail,
                                                    prominent_features: currentValue.prominent_features,
                                                    sku: currentValue.sku,
                                                    price: currentValue.price ? parseFloat(currentValue.price).toLocaleString("vi") : ''
                                                }
                                            })
                                        }
                                        return previousValue.sort(function (a: any, b: any) {
                                            if (a.size.title < b.size.title) {
                                                return 1
                                            }
                                            if (a.size.title > b.size.title) {
                                                return -1
                                            }
                                            return 0
                                        })
                                    }, [])
                                },
                                size: ite.size || null,
                            }
                        }

                        if (!ite.color && ite.size && typeof ite.size === 'object') {
                            return {
                                size: {
                                    ...ite,
                                    sku: ite.sku,
                                    price: ite.price ? parseFloat(ite.price).toLocaleString("vi") : '',
                                    quick_detail: ite.quick_detail,
                                    ...ite.size,
                                    images: ite.images ? ite.images.map((ie: any) => getImageField(ie, "directus_files_id")) : null,
                                    variants: item.children?.reduce((previousValue: any, currentValue: any) => {
                                        if (currentValue.size.id === ite.size.id) {
                                            previousValue.push({
                                                id: ite.size.id,
                                                title: ite.size.title,
                                                // size: currentValue.size || null,
                                                product: {
                                                    id: currentValue.id,
                                                    images: currentValue.images.map((ite: any) => getImageField(ite, "directus_files_id")),
                                                    quick_detail: currentValue.quick_detail,
                                                    sku: currentValue.sku,
                                                    price: currentValue.price ? parseFloat(currentValue.price).toLocaleString("vi") : ''
                                                }
                                            })
                                        }
                                        return previousValue.sort(function (a: any, b: any) {
                                            if (a.size.title < b.size.title) {
                                                return 1
                                            }
                                            if (a.size.title > b.size.title) {
                                                return -1
                                            }
                                            return 0
                                        })
                                    }, [])
                                },
                            }
                        }
                    })

                output['attributes'] = attributes.reduce((previousValue: any, currentValue: any) => {

                    if (currentValue && typeof currentValue === 'object') {
                        if (currentValue.color) {
                            if (!previousValue.color.find((ite: any) => ite.id === currentValue.color.id)) {
                                previousValue.color.push(currentValue.color);
                                output["images"] = [...currentValue.color.images]
                            } else {
                                previousValue.color = previousValue.color.map((ite: any) => {
                                    if (ite.id === currentValue.color.id) {
                                        output["images"] = [...output["images"], ...currentValue.color.images]
                                        return {
                                            ...ite,
                                            images: [...ite.images, ...currentValue.color.images]
                                        }
                                    }
                                    output["images"] = [...output["images"], ...ite.images]

                                    return {
                                        ...ite
                                    }
                                })
                            }
                        }

                        if (currentValue.size) {
                            if (!previousValue.size.find((ite: any) => ite.id === currentValue.size.id)) {
                                previousValue.size.push(currentValue.size);
                            }
                        }
                        return previousValue;
                    }
                    return previousValue
                }, {
                    color: [],
                    size: []
                })

                output['children'] = item.children.map((ite: any) => ({
                    ...ite,
                    price: ite.price ? parseFloat(ite.price).toLocaleString("vi") : '',
                    images: ite.images ? ite.images.map((ite: any) => getImageField(ite, "directus_files_id")) : null,
                }))
            }

            if (item.related_products && item.related_products[0] && item.related_products[0].related_products_id) {
                output["related_products"] = item.related_products.map((ite: any) => ({
                    ...ite.related_products_id,
                    images: ite.related_products_id.images ? ite.related_products_id.images.map((ite: any) => getImageField(ite, "directus_files_id")) : null,
                    url: generateUrlByNameRoute('product_detail', {
                        id: ite.related_products_id.id,
                        slug: ite.related_products_id.slug
                    })
                }))
            }

            return output
        } catch (e: any) {
            consola.error('ERR generateProductDto : ', e)
            return {}
        }
    }

    const generateProductCategoryDto = (
        item: ProductCategoryTypeEntity,
        brand?: any
    ): ProductCategoryTypeDto | any => {
        try {
            let output = {
                ...item,
                ...generateDefaultFieldDirectus(item),
                url: (brand ? generateUrlByNameRoute('category_detail', {
                    id: item.id,
                    slug: item.slug
                }) + `?brand=${brand?.slug} ` :
                    generateUrlByNameRoute('category_detail', {
                        id: item.id,
                        slug: item.slug
                    }))
                ,
                translations: {
                    title: item.title || null,
                    decsription: item.description || null
                }
            }

            // if (item.products && Array.isArray(item.products)) {
            //     output['products'] = item.products.reduce((previousValue: any, currentValue: any) => {
            //         if (currentValue && typeof currentValue === 'object') {
            //             previousValue.push(generateProductDTO(currentValue, languageCode))
            //         }
            //         return previousValue
            //     }, [])
            // }

            return output
        } catch (e: any) {
            consola.error('ERR generateProductCategoryDto : ');
            consola.debug(e);
            return {}
        }
    }

    const generateProductCollectionDto = (
        item: ProductCollectionTypeEntity
    ): ProductCategoryTypeDto | any => {
        try {
            let output = {
                ...generateDefaultFieldDirectus(item),
                images: item.images?.map((ite: any) => getImageField(ite, 'directus_files_id')) || null,
                url: generateUrlByNameRoute('collection_detail', {
                    id: item.id,
                    slug: item.slug
                }),
                translations: {
                    title: item.title || null,
                    decsription: item.description || null
                },
            }

            return output
        } catch (e: any) {
            consola.error('ERR generateProductCollectionDto: ', e)
            consola.debug(e);
            return {}
        }
    }

    const generateProductBrandDto = (
        item: ProductBrandTypeEntity
    ): ProductBrandTypeDto | any => {
        try {
            let output = {
                ...item,
                translations: {
                    title: item.title,
                },
            }

            if (item.products && Array.isArray(item.products)) {
                let categories = item.products.reduce((previousValue: any, currentValue: any) => {
                    if (currentValue && typeof currentValue === 'object') {
                        previousValue.push(currentValue.category)
                    }
                    return previousValue
                }, [])
                
                let distinctCats = [...new Set(categories.map((ite: any) => ite.id))]
                output['product_categories'] = distinctCats.map((item: any) => categories.find((ite: any) => item === ite.id)).map((ie: any) => generateProductCategoryDto(ie, item)).sort(function (a: any, b: any) {
                    if (a.sort < b.sort) {
                        return -1
                    }
                    if (a.sort > b.sort) {
                        return 1
                    }
                    return 0
                })
            }

            return output
        } catch (e: any) {
            consola.error('ERR generateProductBrandDto: ', e);
            consola.debug(e);
            return {}
        }
    }

    return {
        generateProductCategoryDto,
        generateProductCollectionDto,
        generateProductDto,
        generateProductBrandDto
    }
}
