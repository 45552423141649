import { Query } from '@directus/types'
import { consola } from 'consola'
import { useProductUtils } from './useProductUtils'
import {
    ProductTypeDto,
} from './useProductType'

export const useProducts = (settingsData?: any) => {
    const { getItems } = useDirectusItems();
    const { toNonAccentVietnamese, calTotalPage } = useParseData();
    const settings: any = settingsData || inject('globalSettings')
    const {
        generateProductCategoryDto,
        generateProductCollectionDto,
        generateProductDto,
        generateProductBrandDto
    } = useProductUtils(settings)

    let _productFields = [
        'id',
        'status',
        'title',
        'thumbnail',
        'title',
        'description',
        'slug',
        'sku',
        'price',
        'parent',
        'children',
        'color.id',
        'color.icon',
        'color.title',
        'size.id',
        'size.title',
        'type.title',
        'type.id',
        'featured.*',
        'features.attributes_id.id',
        'features.attributes_id.title',
        'collection.id',
        'collection.title',
        'category.id',
        'category.title',
        'brand.id',
        'brand.title',
        'related_products.*',
        'related_products.related_products_id.id',
        'related_products.related_products_id.slug',
        'related_products.related_products_id.title',
        'related_products.related_products_id.images.*',
        'related_products.related_products_id.sku',
        'related_products.related_products_id.quick_detail',
        'related_products.related_products_id.description',
        'related_products.related_products_id.price',
    ]

    const getProducts = async (options?: { id?: any, slug?: string, seo_fields: Array<any> }) => {

        let _fields: Array<any> = []
        let and: Array<any> = [
            {
                status: { _eq: "published" }
            },
        ]

        if (options?.id) {
            and.push({
                id: { _eq: +options.id }
            })
            _fields = [
                ..._productFields,
                'description',
                'quick_detail',
                'prominent_features',
                'specifications',
                'pecifications_link',
                'guide_links',
                'support_info',
                'trending',
                'images.*',
                'collection.id',
                'collection.title',
                'collection.slug',
                'collection.thumbnail',
                'category.id',
                'category.title',
                'category.slug',
                'children',
                'children.id',
                'children.images.*',
                'children.quick_detail',
                'children.sku',
                'children.prominent_features',
                'children.price',
                'children.color.id',
                'children.color.icon',
                'children.color.title',
                'children.color.images.*',
                'children.size.id',
                'children.size.title',
                'children.type.titlse',
                'children.type.id',
                'seo_detail.*',
                ...options?.seo_fields || []

            ]
        }

        if (options?.slug) {
            and.push({
                slug: { _eq: options.slug }
            })
            _fields = [
                ..._productFields,
                'description',
                'specifications',
                'prominent_features',
                'specifications_link',
                'guide_links',
                'trending',
                'support_info',
                'images.*',
                'featured.*',
                'collection.id',
                'collection.title',
                'collection.slug',
                'collection.thumbnail',
                'category.id',
                'category.title',
                'category.slug',
                'children',
                'children.id',
                'children.images.*',
                'children.quick_detail',
                'children.sku',
                'children.prominent_features',
                'children.price',
                'children.color.id',
                'children.color.icon',
                'children.color.title',
                'children.color.images.*',
                'children.size.id',
                'children.size.title',
                'children.type.title',
                'children.type.id',
                'seo_detail.*',
                ...options?.seo_fields || []

            ]
        }

        return getItems({
            collection: "products",
            params: {
                fields: [..._fields],
                filter: {
                    _and: [...and]
                },
                sort: ['-id']
            }
        }).then((data: any) => {
            return generateProductDto(data[0])
        }).catch(e => {
            consola.error("Error: getProducts!");
            consola.debug(e);
            return {};
        })
    }

    const getTrendingProducts = async (options?: {
        query?: { limit?: any }
    }) => {

        let _trendingFields = [
            'id',
            'status',
            'title',
            'thumbnail',
            'title',
            'description',
            'slug',
            'sku',
            'price',
            'parent',
            'images.*',
            'children',
            'color.id',
            'color.icon',
            'color.title',
            'size.id',
            'size.title',
            'type.title',
            'type.id',
        ]
        let and: Array<any> = [
            {
                status: { _eq: "published" },

            },
            {
                trending: { _eq: true }
            },
            {
                parent: {
                    _null: true
                }
            }
        ]

        return getItems({
            collection: "products",
            params: {
                fields: [..._trendingFields],
                filter: {
                    _and: [...and]
                },
                sort: ['-id'],
                limit: options?.query?.limit || 3
            }
        }).then((data: any) => {
            return data.map((ite: any) => generateProductDto(ite))
        }).catch(e => {
            consola.error("Error: getTrendingProducts!");
            consola.debug(e);
            return {};
        })
    }

    const getRelatedProducts = async (options?: {
        slug?: any,
        collection_ids?: any,
        category_ids?: any
        query?: { limit?: any }
    }) => {
        // consola.info(options)
        let and: Array<any> = [
            {
                status: { _eq: "published" },

            },
            {
                parent: {
                    _null: true
                }
            }
        ]
        let _fields = [
            'id',
            'status',
            'title',
            'thumbnail',
            'title',
            'name',
            'description',
            'slug',
            'sku',
            'price',
            'parent',
            'images.*',
            'children',
            'color.id',
            'color.icon',
            'color.title',
            'size.id',
            'size.title',
        ]

        if (options?.slug) {
            and.push(
                { slug: { _neq: options?.slug } })
        }

        if (options?.collection_ids) {
            if (options.collection_ids[0]) {
                let ids = options.collection_ids.map((ite: any) => {
                    return typeof ite === 'object' ? ite.id : ite
                    // consola.info(ite)
                    // if (ite) {
                    //     and.push(
                    //         {
                    //             collection: {
                    //                 id: { _in: typeof ite === 'object' ? ite.id : ite }
                    //             }
                    //         })
                    // }
                })
                and.push(
                    {
                        collection: {
                            id: { _in: ids }
                        }
                    })

            } else if (typeof options.collection_ids === 'object') {
                if (options.collection_ids.id) {
                    and.push(
                        {
                            collection: {
                                id: { _eq: options.collection_ids.id }
                            }
                        })
                }
            }

        } else
            if (options?.category_ids) {
                if (options.category_ids[0]) {
                    let ids = options.category_ids.map((ite: any) => {
                        return typeof ite === 'object' ? ite.id : ite
                        // if (ite) {
                        //     and.push({
                        //         category: {
                        //             id: { _in: typeof ite === 'object' ? ite.id : ite }
                        //         }
                        //     })
                        // }
                    })

                    and.push({
                        category: {
                            id: { _in: ids }
                        }
                    })

                } else if (typeof options.category_ids === 'object') {
                    if (options.category_ids.id) {
                        and.push({
                            category: {
                                id: { _eq: options.category_ids.id }
                            }
                        })
                    }
                }

            }

        // console.log(and)
        return getItems({
            collection: "products",
            params: {
                fields: [..._fields],
                filter: {
                    _and: [...and]
                },
                sort: ['featured', '-id'],
                limit: options?.query?.limit || 4
            }
        }).then((data: any) => {
            return data.map((ite: any) => generateProductDto(ite))
        }).catch(e => {
            consola.error("Error: getRelatedProducts!");
            consola.debug(e);
            return {};
        })
    }


    const getProductCategories = async (options?: {
        id?: any,
        slug?: string,
        seo_fields?: Array<any>
    }) => {

        let and: Array<any> = [
            {
                status: { _eq: "published" }
            }

        ]

        if (options?.id) {
            and.push({
                id: { _eq: +options.id }
            })
        }

        if (options?.slug) {
            and.push({
                slug: { _eq: options.slug }
            })
        }

        return getItems({
            collection: "product_categories",
            params: {
                fields: ['id', 'status', 'icon', 'title', 'slug', 'description', 'seo_detail.*'],
                filter: {
                    _and: [...and]
                },
                sort: ['sort']
            }
        }).then((data: any) => {
            if (options?.id || options?.slug) {
                return generateProductCategoryDto(data[0])
            } else {
                return data.map((ite: any) => generateProductCategoryDto(ite))
            }
        }).catch(e => {
            consola.error("Error: getProductCategories: ");
            consola.debug(e);
            return {};
        })
    }


    const getProductCollections = async (options?: {
        id?: any,
        slug?: string,
        query?: { limit?: any, page?: any }
    }) => {

        let _fields = [
            'id',
            'status',
            'thumbnail',
            'title',
            'name',
            'slug',
            'sort'
        ]
        let and: Array<any> = [
            {
                status: { _eq: "published" }
            }

        ]

        if (options?.id) {
            and.push({
                id: { _eq: +options.id }
            })
            _fields = [
                ..._fields,
                'banner',
                'description',
                'product_categories',
                'images.*',
                'seo_detail.*'
            ]
        }

        if (options?.slug) {
            and.push({
                slug: { _eq: options.slug }
            })
            _fields = [
                ..._fields,
                'banner',
                'description',
                'product_categories',
                'images.*',
                'seo_detail.*'
            ]
        }
        let _params: any = {
            fields: [..._fields],
            filter: {
                _and: [...and]
            },
        }

        if (options?.query) {
            _params = {
                ..._params,
                limit: options.query.limit || 12,
                page: options.query.page || 1,
                meta: 'filter_count',
                sort: ['sort', '-id'],
            }
        }

        return getItems({
            collection: "product_collections",
            params: {
                ..._params
            }
        }).then((data: any) => {
            if (options?.id || options?.slug) {
                return generateProductCollectionDto(data[0])
            } else {
                return {
                    collections: data.data.map((ite: any) => generateProductCollectionDto(ite)),
                    total_page: calTotalPage(+data.meta.filter_count, options?.query?.limit || 12),
                    filter_count: data.meta.filter_count,
                    current_page: options?.query?.page || 1
                }
            }
        }).catch(e => {
            consola.error("Error: getProductCollections: ");
            consola.debug(e);
            return {};
        })
    }


    const getProductCollectionsMegaMenu = async (options?: {
        query?: { limit?: any, page?: any }
    }) => {

        let _fields = [
            'id',
            'status',
            'title',
            'name',
            'slug',
        ]


        let _params: any = {
            fields: [..._fields],
            filter: { status: { _eq: "published" } },
            sort: ['-id'],
        }

        if (options?.query) {
            _params = {
                ..._params,
                limit: options.query.limit || 12,
                sort: ['sort', '-id'],
            }
        }

        return getItems({
            collection: "product_collections",
            params: { ..._params }
        }).then((data: any) => {

            return data.map((ite: any) => generateProductCollectionDto(ite))
        }).catch(e => {
            consola.error("Error: getProductCollectionsMegaMenu: ");
            consola.debug(e);
            return {};
        })
    }


    const getProductBrandsMegaMenu = async () => {

        let _fields = [
            'id',
            'status',
            'title',
            'slug',
            'products.id',
            'products.category.id',
            'products.category.sort',
            'products.category.status',
            'products.category.title',
            'products.category.slug'
        ]


        let _params: any = {
            fields: [..._fields],
            filter: { status: { _eq: "published" } },
            sort: ['sort,-title'],
        }

        return getItems({
            collection: "product_brands",
            params: { ..._params }
        }).then((data: any) => {
            return data.map((ite: any) => generateProductBrandDto(ite))
        }).catch(e => {
            consola.error("Error: getProductBrandsMegaMenu: ");
            consola.debug(e);
            return {};
        })
    }

    const getFeaturedCollections = async (options?: {
        query?: { limit?: any }
    }) => {

        let _fields = [
            'id',
            'status',
            'featured',
            'thumbnail',
            'title',
            'name',
            'slug',
        ]
        let and: Array<any> = [
            {
                status: { _eq: "published" }
            },
            {
                featured: { _eq: true }
            },

        ]

        return getItems({
            collection: "product_collections",
            params: {
                fields: [..._fields],
                filter: {
                    _and: [...and]
                },
                limit: options?.query?.limit || 12,
                sort: ['sort', '-id'],

            }
        }).then((data: any) => {
            return data.map((ite: any) => generateProductCollectionDto(ite))
        }).catch(e => {
            consola.error("Error: getFeaturedCollections!");
            consola.debug(e);
            console.log(e)
            return {};
        })
    }

    const getProductFilterData = async (options: {
        collection?: { slug?: any },
        category?: { slug?: any }
    }) => {

        if (options.collection) {
            return Promise.all([
                getItems({
                    collection: "product_collections",
                    params: {
                        fields: [
                            'product_categories.product_categories_id.id',
                            'product_categories.product_categories_id.slug',
                            'product_categories.product_categories_id.title',
                        ],
                        filter: {
                            _and: [
                                {
                                    status: {
                                        _eq: 'published'
                                    }
                                },
                                {
                                    slug: {
                                        _eq: options.collection.slug
                                    }
                                }
                            ]
                        },
                        sort: ['title']
                    }
                }),
                getItems({
                    collection: "product_categories",
                    params: {
                        fields: [
                            '*',
                            'products',
                            'products.collection.id',
                            'products.collection.slug'
                        ],
                        filter: {
                            _and: [
                                {
                                    status: { _eq: 'published' }
                                },
                                {
                                    products: {
                                        collection: {
                                            slug: { _eq: options.collection.slug }
                                        }
                                    }
                                }
                            ]
                        },
                        sort: ['title']
                    }
                }),
                getItems({
                    collection: "attributes",
                    params: {
                        fields: [
                            'id',
                            'title',
                            'type',
                            'icon',
                            '*'
                        ],
                        filter: {
                            _and: [
                                {
                                    status: { _eq: 'published' }
                                },
                                {
                                    _or: [
                                        {
                                            type: { _eq: 'color' }
                                        },
                                    ]
                                }
                            ]
                        },
                        sort: ['title']
                    }
                }),
                getItems({
                    collection: "attributes",
                    params: {
                        fields: [
                            'id',
                            'title',
                            'type',
                            'icon',
                            'products_features.*',
                            // 'products_features.products_id.*',
                            'products_features.products_id.collection.id',
                            'products_features.products_id.collection.slug',
                        ],
                        filter: {
                            _and: [
                                {
                                    status: { _eq: 'published' }
                                },
                                {
                                    _and: [
                                        {
                                            type: { _eq: 'feature' }
                                        },
                                        {
                                            products_features: {
                                                products_id: {
                                                    collection: {
                                                        _null: false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            products_features: {
                                                products_id: {
                                                    collection: {
                                                        slug: {
                                                            _eq: options.collection.slug
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        sort: ['title']
                    }
                })

            ]).then((data: any) => {

                let categories = data[1].map((ite: any) => ({
                    id: ite.id,
                    title: ite.title
                })).sort(function (a: any, b: any) {
                    if (a.title < b.title) {
                        return -1
                    }
                    if (a.title > b.title) {
                        return 1
                    }
                    return 0
                });


                return {
                    categories,
                    colors: data[2].filter((ite: any) => ite.type === 'color').sort(function (a: any, b: any) {
                        if (a.title < b.title) {
                            return -1
                        }
                        if (a.title > b.title) {
                            return 1
                        }
                        return 0
                    }),
                    features: data[3]
                }
            }).catch(e => {
                consola.error("Error: getProductFilterData: ");
                consola.debug(e);
                return {};
            })
        } else if (options.category) {
            return Promise.all([
                getItems({
                    collection: "attributes",
                    params: {
                        fields: [
                            'id',
                            'title',
                            'type',
                            'icon',
                            'category.*',
                            'collections.*'
                        ],
                        filter: {
                            _and: [
                                {
                                    status: { _eq: 'published' }
                                },
                            ]
                        },
                    }
                }),
                getItems({
                    collection: "attributes",
                    params: {
                        fields: [
                            'id',
                            'title',
                            'type',
                            'icon',
                            'products_features.*',
                            'products_features.products_id.*',
                            'products_features.products_id.category.*',
                            'products_features.products_id.category.id',
                        ],
                        filter: {
                            _and: [
                                {
                                    status: { _eq: 'published' }
                                },
                                {
                                    _and: [
                                        {
                                            type: { _eq: 'feature' }
                                        },
                                        {
                                            products_features: {
                                                products_id: {
                                                    category: {
                                                        _null: false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            products_features: {
                                                products_id: {
                                                    category: {
                                                        slug: {
                                                            _eq: options.category.slug
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                    }
                })

            ]).then((data: any) => {
                let terms = data[0].map((ite: any) => {
                    return ite.type
                })
                terms = [...new Set(terms)]
                let termOrderedArray = terms.reduce(function (output: any, currentValue: any) {
                    let items = data[0].filter((ite: any) => ite.type === currentValue)
                    output[currentValue] = items.sort(function (a: any, b: any) {
                        if (toNonAccentVietnamese(a.title) < toNonAccentVietnamese(b.title)) {
                            return -1
                        }
                        if (toNonAccentVietnamese(a.title) > toNonAccentVietnamese(b.title)) {
                            return 1
                        }
                        return 0
                    })
                    return output
                }, {})

                delete termOrderedArray['size'];
                termOrderedArray['color'] = termOrderedArray['color'].sort(function (a: any, b: any) {
                    if (a.title < b.title) {
                        return -1
                    }
                    if (a.title > b.title) {
                        return 1
                    }
                    return 0
                })

                return {
                    colors: { ...termOrderedArray['color'] },
                    features: data[1].sort(function (a: any, b: any) {
                        if (a.title < b.title) {
                            return -1
                        }
                        if (a.title > b.title) {
                            return 1
                        }
                        return 0
                    })
                }


            }).catch(e => {
                consola.error("Error: getProductCollections: ");
                consola.debug(e);
                return {};
            })

        }

    }

    const searchProducts = async (
        page?: {
            categories?: { slug?: any }
            collection?: { slug?: any }
        },
        options?: {
            brand?: any
            text?: string
            categories?: Array<string | number>
            types?: Array<string | number>
            features?: Array<string | number>
            price_range?: Array<any>
            colors?: Array<any>
            from?: string | number
            to?: string | number
            sort?: {
                featured?: boolean
                newest?: boolean
                oldest?: boolean
                descending_price?: boolean
                ascending_price?: boolean
                az?: boolean
                za?: boolean
            }
        },
        query?: Query | any
    ): Promise<Array<ProductTypeDto> | any> => {

        let _and: Array<any> = [
            {
                status: { _eq: 'published' }
            },
            {
                parent: { _null: true }
            }
        ]


        let _sort: Array<string> = []

        if (page?.categories) {
            _and.push({
                category: {
                    slug: { _eq: page.categories.slug }
                }
            })
        }

        if (page?.collection) {
            _and.push({
                collection: {
                    slug: { _eq: page.collection.slug }
                }
            })
        }

        if (options?.text) {
            _and.push({
                _and: [
                    {
                        _or: [
                            {
                                title: {
                                    _icontains: options.text.toString()
                                }
                            },
                            {
                                description: {
                                    _icontains: options.text.toString()
                                }
                            }

                        ]
                    }
                ]
            })
        }

        if (options?.categories && options.categories[0]) {
            _and.push({
                _or: [
                    {
                        category: {
                            id: { _in: options.categories }
                        }
                    },
                ]
            })
        }


        if (options?.colors && options.colors[0]) {
            _and.push({
                _or: [
                    {
                        color: { _in: options.colors },
                    },
                    {
                        children: {
                            color: { _in: options.colors }
                        }
                    }
                ]
            })
        }

        if (options?.features && options.features[0]) {
            _and.push({
                features: {
                    attributes_id: { _in: options.features }
                }
            })
        }

        if (options?.brand) {
            _and.push({
                brand: {
                    slug: {
                        _eq: options.brand.trim()
                    }
                }
            })
        }


        if (options?.sort) {
            if (options.sort.featured) {
                _sort.push('featured')
            }
            if (options.sort.newest) {
                _sort.push('-id')
            }
            if (options.sort.oldest) {
                _sort.push('id')
            }
            if (options.sort.descending_price) {
                _sort.push('-price')
            }
            if (options.sort.ascending_price) {
                _sort.push('price')
            }

            if (options.sort.az) {
                _sort.push('title')
            }

            if (options.sort.za) {
                _sort.push('-title')
            }

        }

        return getItems({
            collection: "products",
            params: {
                filter: { _and },
                fields: [
                    'id',
                    'collection.id',
                    'collection.slug',
                    'status',
                    'title',
                    'thumbnail',
                    'title',
                    'description',
                    'slug',
                    'sku',
                    'price',
                    'parent',
                    'color.id',
                    'color.icon',
                    'color.title',
                    'size.id',
                    'size.title',
                    'type.title',
                    'images.*',
                    'variant',
                    'featured',
                    'category.id',
                    'category.slug',
                    'quick_detail',
                    'children.id',
                    'children.images.*',
                    'children.quick_detail',
                    'children.sku',
                    'children.price',
                    'children.color.id',
                    'children.color.icon',
                    'children.color.title',
                    'children.size.id',
                    'children.size.title',
                    'children.type.title',
                    'children.type.id',
                    'features.attributes_id.id',
                    'features.attributes_id.title',
                    'brand.id',
                    'brand.title',
                    'brand.slug'
                ],
                sort: [..._sort, 'featured', '-date_created'],
                limit: query && query.limit ? +parseInt(<string>query.limit) : 9,
                page: query && query.page ? +parseInt(<string>query.page) : 1,
                ...(query ?? {}),
                // @ts-ignore
                meta: 'filter_count'
            }
        })
            .then((products: any) => {

                if (products && products.data && Array.isArray(products.data)) {
                    return {
                        products: products.data.map((item: any) =>
                            generateProductDto(item)
                        ),
                        page: query?.page || 1,
                        filter_count: products.meta?.filter_count || 0,
                    }
                } else
                    return {
                        products: [],
                        page: query?.page || 1,
                        filter_count: 0
                    }
            })
            .catch((e) => {
                consola.error("Error: searchProducts: ");
                consola.debug(e);
                return {};
            })
    }

    const actionPostOrder = async (body: { [key: string]: any }, token?: string) => {
        let order = {
            name: body.name,
            email: body.email,
            phone: body.phone,
            receive_promotion: body.receive_promotion,
            note: body.note,
            products: body.products.map((ite: any) => ({
                id: ite.id,
                quantity: ite.quantity,
                price: ite.priceTerm,
            })),
            product_details: body.products.map((ite: any) => ({
                id: ite.id,
                title: ite.title ? ite.title : '',
                sku: ite.sku,
                color: ite.iconTitle ? ite.iconTitle : '',
                size: ite.size ? ite.size.title : '',
                quantity: ite.quantity,
                price: ite.priceTerm,
            })),
            total_price: body.totalPrice,
            total_payment: body.totalPrice ? parseFloat(body.totalPrice).toLocaleString("vi") : ''
        }

        let text = ''
        let products = order.product_details.map((ite: any) => {
            text = text + '<p>' + "Tên sản phẩm: " + ite.title + ' - Mã sản phẩm: ' + ite.sku + ' - Số lượng: ' + ite.quantity + '</p>'
        })

        body = {
            ...order,
            products: {
                create: order.products.map((ite: any) => ({
                    "orders_id": "+",
                    "products_id": {
                        "id": ite.id
                    },
                    "quantity": ite.quantity
                })),
                update: [],
                delete: []
            },
            detail: text
        }

        if (token) {
            return fetch(`/api/order?response=${token}`, {
                method: 'POST',
                body: JSON.stringify(body)
            })
                .then((response: any) => {
                    return response.json()
                })
                .catch((e) => {
                    consola.error('Error actionPostOrder: ')
                    consola.warn(e)
                    return {}
                })
        }
        return fetch(`/api/order`, {
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then((response: any) => {
                return response.json()
            })
            .catch((e) => {
                consola.error('Error actionPostOrder: ', e)
                return {}
            })
    }

    return {
        searchProducts,
        getTrendingProducts,
        getProductCategories,
        getProductCollections,
        getProductCollectionsMegaMenu,
        getProducts,
        getProductFilterData,
        getRelatedProducts,
        getFeaturedCollections,
        actionPostOrder,
        getProductBrandsMegaMenu
    }
}
